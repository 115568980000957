<template>
  <div class="container-main-content forgetPassword">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-3">Quên mật khẩu</h4>
        <p class="f14 text-second-color mb-4">
          Nhớ ra rồi?
          <a href="#" class="link-primary-color" @click.prevent="handleLogin">
            Quay về trang đăng nhập.
          </a>
        </p>
        <div class="content-inner__body-form">
          <ValidationObserver ref="formEmail">
            <form @keyup.enter="handleSubmit">
              <ValidationProvider
                name="Địa chỉ email"
                rules="required|min3|email"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="text"
                    v-model.trim="email"
                    class="form-control"
                    id="floatingEmail"
                    placeholder="name@example.com"
                  />
                  <label for="floatingEmail">Địa chỉ email</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
          <button
            type="button"
            class="btn btn-primary mb-4"
            @click="handleSubmit"
          >
            TIẾP TỤC
          </button>
        </div>
      </div>
      <Footer />
    </div>

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { UserService } from "../../services/user.service";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
      email: "",
      loading: {
        isLoading: false,
        fullPage: true,
      },
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.formEmail.validate().then(async (success) => {
        if (success) {
          try {
            this.loading.isLoading = true;
            const response = await UserService.forgetPassword(this.email);
            if (response.code === "SUCCESS") {
              if (this.currentQueries) {
                this.$router.push({
                  path: `/forget-password-mail${this.currentQueries}&email=${this.email}`,
                });
              } else {
                this.$router.push({
                  path: `/forget-password-mail?email=${this.email}`,
                });
              }
            } else {
              alert(response.data.code);
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.loading.isLoading = false;
          }
        }
      });
    },
    handleLogin() {
      this.$router.push({ path: `/login${this.currentQueries}` });
    },
  },
};
</script>
